import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {       
    fetchSchedules(ctx, queryParams) { 
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + '/schedules', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchScheduleByDate(ctx, { date }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + `/schedulebydate?schedule_date=${date}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSchedule(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + `/schedule?schedule_id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchScheduleJob(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + `/schedulejobs?schedule_id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },  
    fetchScheduleJobTaskWithDetails(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + `/scheduleJobTasksWithDetails?schedule_job_id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchScheduleJobToolsWithDetails(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + `/schedulejobtoolswithdetails?schedule_job_id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchScheduleJobMaterialsWithDetails(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + `/schedulejobmaterialswithdetails?schedule_job_id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
    fetchScheduleJobWorkersWithDetails(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + `/schedulejobworkerswithdetails?schedule_job_id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchScheduleJobWithDetail(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + `/schedulejobswithdetails?schedule_id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
    addSchedule(ctx, scheduleData) {
      var bodyFormData = new FormData();
      bodyFormData.append('schedule_date', scheduleData.schedule_date);
      bodyFormData.append('schedule_remark', scheduleData.schedule_remark);
      bodyFormData.append('schedule_status', scheduleData.schedule_status); 
      bodyFormData.append('schedule_created_by', scheduleData.schedule_created_by); 

      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_HOST + '/schedule',
          bodyFormData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSchedule(ctx, scheduleData) {
      var bodyFormData = new FormData();
      bodyFormData.append('schedule_date', scheduleData.schedule_date);
      bodyFormData.append('schedule_remark', scheduleData.schedule_remark);
      bodyFormData.append('schedule_status', scheduleData.schedule_status); 
      bodyFormData.append('schedule_updated_by', scheduleData.schedule_updated_by); 
      bodyFormData.append('schedule_id', scheduleData.schedule_id); 

      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_HOST + '/schedule',
          bodyFormData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateScheduleJobs(ctx, { id, schedules }) {
      
      var bodyFormData = new FormData(); 
      bodyFormData.append('schedule_id', id);
      bodyFormData.append('jsonSchedule', schedules);
      
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_HOST + '/scheduleJobs',
          bodyFormData, {
              headers:{
                "Content-Type": "application/json"
              }
          }
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateScheduleJob(ctx, schedule) {
      
      var bodyFormData = new FormData();
      bodyFormData.append('jsonSchedule', schedule);

      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_HOST + '/scheduleJob',
          bodyFormData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
